import React from 'react'
import styled from 'styled-components'
import { AnchorLink } from 'gatsby-plugin-anchor-links'

import { maxScreen } from 'styles/_breakpoints'

const Link = styled(AnchorLink)`
  padding: 0.85rem 2.25rem;
  text-transform: uppercase;
  font-size: clamp(2rem, 2vw, 2.25rem);
  border-radius: 0.25rem;
  border: 0.1rem solid ${({ theme: { colors } }) => colors.pink};
  background: ${({ theme: { colors } }) => colors.pink};
  color: ${({ theme: { colors } }) => colors.white};
  cursor: pointer;

  @media ${maxScreen.desktop} {
    padding: 0.75rem 1.7rem;
  }
`

interface Link {
  to: string
  children: any
}

const Button = ({ to, children }: Link) => {
  return (
    <Link to={to} stripHash>
      {children}
    </Link>
  )
}

export default Button
