import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'

import Theme from 'styles/_theme'
import GlobalStyle from 'styles/_global'

import { AnimationFade } from 'libs/animation'

import { Wrapper } from 'styles/_mixins'
import { deviceScreen, maxScreen } from 'styles/_breakpoints'

import Seo from 'components/Seo/Seo'
import Layout from 'components/Layout/Layout'
import Button from 'components/Button/Button'

const Container = styled.section`
  width: 100%;
  height: 100vh;
  margin: 5rem auto;

  & ${Wrapper} {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;

    & > a {
      width: fit-content;
    }
  }
`

const Content = styled.div`
  width: 100%;
  height: fit-content;
  text-align: center;
`

const Title = styled.h1`
  font-size: clamp(18rem, 25vw, 35rem);
  color: ${({ theme: { colors } }) => colors.black};
`

const Description = styled.p`
  width: 25%;
  margin: -3rem auto 7rem;
  font-size: clamp(1.4rem, 1vw, 2rem);

  @media ${maxScreen.smart} {
    width: 65%;
    margin: -1rem auto 3rem;
  }

  @media ${deviceScreen.smland} {
    width: 40%;
    margin: -1rem auto 5rem;
    line-height: 2rem;
  }

  @media ${deviceScreen.tablet} {
    margin: -2rem auto 5rem;
  }
`

const NotFoundPage = () => {
  const datas = useStaticQuery(graphql`
    query {
      markdownRemark(fileAbsolutePath: { regex: "/error/" }) {
        frontmatter {
          error {
            id
            slug
            title
            descrip
            linkTitle
            linkText
            seoTitle
            seoDescription
          }
        }
      }
    }
  `)

  const { title, descrip, linkText, seoTitle, seoDescription } =
    datas.markdownRemark.frontmatter.error

  return (
    <Theme>
      <GlobalStyle />
      <Layout backgrounds>
        <Seo title={seoTitle} description={seoDescription} />
        <Container>
          <AnimationFade rootMargin='0px 0px' custom={0}>
            <Wrapper>
              <Content>
                <Title>{title}</Title>
                <Description>{descrip}</Description>
                <Button to='/'>{linkText}</Button>
              </Content>
            </Wrapper>
          </AnimationFade>
        </Container>
      </Layout>
    </Theme>
  )
}

export default NotFoundPage
